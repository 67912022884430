svg rect.area {
  fill: transparent;
  stroke: black;
  stroke-width: 0;
  stroke-dasharray: 5,5;
  pointer-events: none;
}

svg rect.rect {
  fill: transparent;
  stroke: transparent;
  stroke-width: 0.5;
  stroke: #0005;
  fill: #6CED4850;
  cursor: pointer;
}

svg rect.rect.active {
  fill: #6CED4880;
  stroke-width: 0.5;
  stroke: black;
}

svg rect.rect:hover:not(.active) {
  fill: transparent;
  stroke-width: 1;
  stroke: black;
  fill: #6CED48AA;
}

svg rect.boundingBox {
  stroke-width: 0;
  /*fill: #afe6f388;*/
  fill: #D4F2F9;
}
svg rect.allbox {
  stroke-width: 0;
  /*fill: #f3b4c288;*/
  fill: url(#diagonalHatch);
}
svg rect.allbox2 {
fill: transparent;
stroke-width: 0.5;
stroke: red;
stroke-dasharray: 5,5;
}

.document { 
  border: solid 1px #e0ddf2;
  background: #FFF;
}

.ruler {
  background: #edf2f6;  
  z-index: 1;
  position: absolute;
  overflow: hidden;
}
.ruler .slider {
  position: absolute;
  background: #FFF;
}
.ruler.horizontal .slider {
  height: 20px;
}
.ruler.vertical .slider {
  width: 20px;
}

.ruler.horizontal {
  height: 20px;
  top: 81px;
  left: 0px;
  right: 0px;
  border-bottom: 1px solid #e0ddf2;
}
.ruler.vertical {
  width: 20px;
  top: 0px;
  left: 511px;
  bottom: 0px;
  border-right: 1px solid #e0ddf2;
}
.ruler.true {
  left: 511px
}
.ruler.false {
  left:61px
}
.ruler .units {
  width: 100%;
  height: 100%;
}

.ruler.horizontal .slider svg {
  position: absolute;
  top: 10px;
  width: 100%;
}

.ruler.vertical .slider svg {
  position: absolute;
  left: 10px;
  height: 100%;
}

.ruler.horizontal .units {
  background-image: linear-gradient(
      90deg,
      rgba(73, 73, 73, 0.5) 0,
      rgba(73, 73, 73, 0.5) 2%,
      transparent 2%
    ),
    linear-gradient(180deg, #ffffff 50%, transparent 50%),
    linear-gradient(
      90deg,
      transparent 50%,
      rgba(73, 73, 73, 0.5) 50%,
      rgba(73, 73, 73, 0.5) 52%,
      transparent 52%
    ),
    linear-gradient(180deg, #ffffff 70%, transparent 70%),
    linear-gradient(
      90deg,
      transparent 10%,
      rgba(73, 73, 73, 0.4) 10%,
      rgba(73, 73, 73, 0.4) 12%,
      transparent 12%,
      transparent 20%,
      rgba(73, 73, 73, 0.4) 20%,
      rgba(73, 73, 73, 0.4) 22%,
      transparent 22%,
      transparent 30%,
      rgba(73, 73, 73, 0.4) 30%,
      rgba(73, 73, 73, 0.4) 32%,
      transparent 32%,
      transparent 40%,
      rgba(73, 73, 73, 0.4) 40%,
      rgba(73, 73, 73, 0.4) 42%,
      transparent 42%,
      transparent 60%,
      rgba(73, 73, 73, 0.4) 60%,
      rgba(73, 73, 73, 0.4) 62%,
      transparent 62%,
      transparent 70%,
      rgba(73, 73, 73, 0.4) 70%,
      rgba(73, 73, 73, 0.4) 72%,
      transparent 72%,
      transparent 80%,
      rgba(73, 73, 73, 0.4) 80%,
      rgba(73, 73, 73, 0.4) 82%,
      transparent 82%,
      transparent 90%,
      rgba(73, 73, 73, 0.4) 90%,
      rgba(73, 73, 73, 0.4) 92%,
      transparent 92%
    );
  background-size: 50px 20px;
  background-repeat: repeat-x;
}

.document > svg {
  display: block;
}

body {
  background: #f4f4f4;
}

svg .demo.rect {
  stroke: black;
  fill: #6ced4888;
}

svg .demo.doc {
  stroke-width: 1;
  stroke-dasharray: 1, 2;
  stroke: #dfd8e6;
  /*fill: #F9CC9D;*/
  fill: #fff;
}
svg .demo.margin {
  /*fill: #C3DEB7;*/
  fill: #f4f1f6;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
  stroke: #dfd8e6;
}

svg .demo.box {
  stroke-width: 1;
  stroke: #dfd8e6;
  /*fill: #A0C5E8;*/
  fill: #e9e5ee;
}

svg .demo.unused {
  stroke-width: 0;
  fill: url(#diagonalHatchDemo);
}

.pricing-anchor {
  position: absolute;
  top: 16px;
  right: 16px;
}

.legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.legend.bbox {
  background-color: #d4f2f9;
}

.legend.rect {
  background-color: #6ced4888;
}

.legend.waste {
  background-color: #d4f2f9;
  overflow: hidden;
  position: relative;
}

.legend.waste:after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: red;
  transform: rotate(45deg);
  position: absolute;
  top: -4px;
  left: 1px;
}

.extra-detail {
  opacity: 0.5;
}

.details-anchor {
  position: absolute;
  top: 16px;
  left: 16px;
}

.guideline {
  position: absolute;
  opacity: 0.35;
  z-index: 0;
  pointer-events: none;
}

.guideline.horizontal {
  width: 100%;
  height: 0px;
  border-top: 1px dashed;
}

.guideline.vertical {
  width: 0px;
  height: 100%;
  border-left: 1px dashed;
}

.logo {
  position: absolute;
  z-index: -10;
  top: 30px;
  right: 20px;
}

.tutorial {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 30px;
  width: 300px;
  height: 130px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 12;
}

.tutorial span.key {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  position: relative;
}

.tutorial span.key.shift {
  width: 120px;
}

.tutorial span.key > span {
  position: absolute;
  bottom: -15px;
  right: 3px;
  font-size: 10px;
}

.tutorial span.a {
  position: absolute;
  left: 5px;
  top: 60px;
}
.tutorial span.w {
  position: absolute;
  left: 60px;
  top: 5px;
}
.tutorial span.s {
  position: absolute;
  left: 60px;
  top: 60px;
}
.tutorial span.d {
  position: absolute;
  left: 115px;
  top: 60px;
}

.tutorial span.r {
  position: absolute;
  right: 75px;
  top: 5px;
}
.tutorial span.key.shift {
  position: absolute;
  right: 5px;
  top: 60px;
}

.tutorial span.move {
  position: absolute;
  bottom: 10px;
  width: 160px;
  text-align: center;
  font-size: 14px;
}

.tutorial span.shift {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 120px;
  text-align: center;
  font-size: 14px;
}
.tutorial span.rotate {
  position: absolute;
  top: 20px;
  right: 5px;
  width: 95px;
  text-align: center;
  font-size: 14px;
}
.tutorial .help {
  opacity: 1;
  transition: opacity 0.2s linear;
}
.tutorial:hover .help {
  opacity: 0.25;
}

.tutorial .close {
  position: absolute;
  font-weight: bold;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0;
}

.tutorial:hover .close {
  opacity: 1;
}

.tutorial .close:hover {
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.ruler.sc-5 .nums .num:nth-child(2n),
.ruler.sc-20 .nums .num:nth-child(2n),
.ruler.sc-40 .nums .num {
  display: none;
}
.ruler.sc-40 .nums .num:nth-child(4n + 1) {
  display: initial;
}

.ruler.sc-5 svg line:nth-child(2n) {
  opacity: 0.35;
}
.ruler.sc-2 svg line {
  opacity: 0.35;
}
.ruler.sc-2 svg line:nth-child(5n + 1) {
  opacity: 1;
}

.ruler span.num {
  position: absolute;
  top: -3px;
  font-size: 10px;
  color: #250048;
  width: 20px;
  text-align: center;
}
.ruler.sc-2 .nums .num {
  display: none;
}

.ruler.sc-2 .nums .num:nth-child(5n + 1) {
  display: initial;
}

.nums.vertical .num {
  text-align: left;
  left: 1px;
}



.guideline .pos.x {
  position: relative;
  top: -25px;
  left: 25px;
}

.guideline .pos.y {
  position: relative;
  top: 25px;
  left: 5px;
}

body {
  overflow: hidden;
}

.document svg text {
  pointer-events: none;
}
