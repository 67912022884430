@import url('https://fonts.googleapis.com/css?family=Montserrat');

html {
  font-family: 'Montserrat';
  font-weight: 500;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}